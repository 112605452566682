<template>
  <v-row>
    <v-col v-if="loading" cols="12">
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </v-col>
    <v-col v-if="loading" cols="12">
      <v-skeleton-loader
        v-bind="attrs"
        type="table-heading, list-item-three-line, image, table-tfoot"
      ></v-skeleton-loader>
    </v-col>
    <template v-if="!loading">
    <v-col cols="12" >
      <dashboard-congratulation-john :totalInterest="summary.interest"></dashboard-congratulation-john>
    </v-col>
    <v-col cols="12" v-if="$can('view any users')">
      <dashboard-statistics-card :users="summary.users"></dashboard-statistics-card>
    </v-col>
    <v-col cols="12" v-if="$can('view any challenges')">
      <dashboard-challenges-statistics :challenges="summary.challenges"></dashboard-challenges-statistics>
    </v-col>

    <v-col cols="12">
      <v-row class="match-height">
        <v-col cols="12" sm="6">
          <statistics-card-vertical
            change=""
            color="success"
            :icon="icons.mdiPoll"
            :statistics="'UGX ' + Intl.NumberFormat().format(summary.savings)"
            stat-title="Total Savings"
            subtitle="Total savings for active challenges"
          ></statistics-card-vertical>
        </v-col>
        <v-col cols="12" sm="6">
          <statistics-card-vertical
            change=""
            color="secondary"
            :icon="icons.mdiCurrencyUsd"
            :statistics="'UGX ' + Intl.NumberFormat().format(summary.withdrawals)"
            stat-title="Total Withdraws"
            subtitle="Total amount of withdrawals made so far"
          ></statistics-card-vertical>
        </v-col>
        <v-col cols="12" sm="6">
          <statistics-card-vertical
            change=""
            color="primary"
            :icon="icons.mdiLabelVariantOutline"
            :statistics="summary.active_challenges"
            stat-title="Active challenges"
            subtitle="Number of challenges currently active"
          ></statistics-card-vertical>
        </v-col>
        <v-col cols="12" sm="6">
          <statistics-card-vertical
            change=""
            color="warning"
            :icon="icons.mdiHelpCircleOutline"
            :statistics="summary.completed_challenges"
            stat-title="Completed challenges"
            subtitle="Number of challenges you have completed"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="false" cols="12" md="8">
      <dashboard-card-deposit-and-withdraw></dashboard-card-deposit-and-withdraw>
    </v-col>
    </template>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import DashboardCongratulationJohn from './DashboardCongratulationJohn.vue'
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardCardDepositAndWithdraw from './DashboardCardDepositAndWithdraw.vue'
import DashboardChallengesStatistics from './DashboardChallengesStatistics.vue'

export default {
  components: {
    StatisticsCardVertical,
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    DashboardCardDepositAndWithdraw,
    DashboardChallengesStatistics,
  },
  data() {
    return {
      loading: true,
      summary: {},
      icons: {
        mdiPoll,
        mdiLabelVariantOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
      },
    }
  },
  mounted() {
    this.getSummary()
  },
  methods: {
    getSummary() {
      this.loading = true
      this.$http
        .get('/summary')
        .then(res => {
          this.summary = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
