<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">User stats</span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2">
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text--primary me-1">Information about users of the app</span>
      <span>😎</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="6" color="primary" md="3" class="d-flex align-center">
          <v-avatar size="44" color="primary" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Total Users
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ users.total }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" color="primary" md="3" class="d-flex align-center">
          <v-avatar size="44" color="success" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Users with challenges
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{users.with_challenges}}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" color="primary" md="3" class="d-flex align-center">
          <v-avatar size="44" color="success" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiTrendingUp }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Users with active challenges
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{users.with_active_challenges}}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" color="primary" md="3" class="d-flex align-center">
          <v-avatar size="44" color="error" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Users without active challenges
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{users.without_active_challenges}}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'

export default {
  setup() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Sales') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Users without challenges') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
  props: {
    users: {
      required: true,
      type: Object,
    },
  },
}
</script>
